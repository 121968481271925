// extracted by mini-css-extract-plugin
export var cards = "cases__cards-go8-s";
export var hero = "cases__hero-7IFBe";
export var heroImg = "cases__heroImg-+glng";
export var heroTitle = "cases__heroTitle-SYgPW";
export var inner = "cases__inner-HXUmm";
export var introImg = "cases__introImg-H77-N";
export var main = "cases__main-+aPxV";
export var message = "cases__message-+Omdi";
export var section = "cases__section-OcVrz";
export var sectionBlur = "cases__sectionBlur-AIOvF";