import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { SiteLayout, Head, Holder, Intro } from '~/components';
import * as cases from '../cases.module.scss';
import * as go from './go.module.scss';

const CasesGoPage = () => (
  <SiteLayout>
    <Head title="カーシェアリングスマートフォンアプリ「GO!」iOS/Android" />

    <StaticImage
      alt=""
      src="./images/hero_go.jpg"
      quality={100}
      className={cases.introImg}
    />

    <Intro>
      <Holder
        tag="iOS/Androidアプリケーション開発 / 運用管理業務"
        phrase="カーシェアリング実証事業<br />スマートフォンアプリ"
        title="GO!"
        description="GO!専用にカスタマイズしたトヨタプリウスをスマートフォンアプリで操作します。予約や乗車・降車のキー操作以外にも、専用のSNSでライドオンやライドシェア募集し、カーシェアリングが全てスマートフォン上で完結するサービスです。現在実証実験中で、研究開発を進めながらサービスの拡大を進めています。"
        type="アルプスアルパイン株式会社受託事業"
      />
    </Intro>

    <section className={cases.sectionBlur}>
      <div className={cases.inner}>
        <h2>アウトライン</h2>
        <p className={go.sectionText}>アルプスアルパイン株式会社は会津大学との共創プロジェクトとして学生向け低コストカーシェアリングサービスを進めています。<br />
          会津ラボはプラットフォーム及びアプリケーションの設計・開発を担当しました。<br />
          現在は会津大学学生による運用を行い、会津大学を中心に様々な研究開発を進めています。</p>

        <div className={go.logoBox}>
          <StaticImage
            alt=""
            src="./images/logo_alps.png"
            quality={80}
            className={go.alpsAlpine}
          />

          <div className={go.logoMultiple}>×</div>

          <StaticImage
            alt=""
            src="./images/logo_aizu.png"
            quality={80}
            className={go.aizuUniversity}
          />
        </div>
      </div>
    </section>

    <section className={`${cases.section} ${go.distance}`}>
      <div className={cases.inner}>
        <h2>プラットフォーム</h2>
        <p className={go.sectionText}>本システムでは弊社が得意とする AWS Lambda を中心としたサーバーレスアーキテクチャーを採用し、拡張性が高く柔軟にスケールするシステムを、低コストで迅速に構築することを可能にしました。他にもセキュアなユーザー認証基盤である Amazon Cognito、高パフォーマンスでスケーラブルな NoSQL データベースである Amazon DynamoDB などを採用しています。</p>
        <StaticImage
          alt=""
          src="./images/img_figure.png"
          quality={80}
        />
        <h2>アプリケーション</h2>
        <p className={go.sectionText}>スマートフォンアプリではOOUIを採用することで、カーシェア・コミュニティ・おすすめ共有・チャットなど多機能ながらも一つのアプリケーションとしてまとまりのあるサービスを提供することができました。操作性についても直感的操作を可能とし、特にカレンダーでは簡単3ステップでカーシェア予約が行えます。<br />
          運転免許証などのユーザー登録からコミュニティでのやりとり、共有、コミュニケーション、カーシェア予約、解錠・施錠まで、全ての機能が入ったスマートフォンアプリケーションです。</p>

        <div className={go.appLayout}>
          <StaticImage
            alt=""
            src="./images/img_app01_go.png"
            quality={80}
            className={go.appImage}
          />
          <StaticImage
            alt=""
            src="./images/img_app02_go.png"
            quality={80}
            className={go.appImage}
          />
          <StaticImage
            alt=""
            src="./images/img_app03_go.png"
            quality={80}
            className={go.appImage}
          />
          <StaticImage
            alt=""
            src="./images/img_app04_go.png"
            quality={80}
            className={go.appImage}
          />
          <StaticImage
            alt=""
            src="./images/img_app05_go.png"
            quality={80}
            className={go.appImage}
          />
        </div>
      </div>
    </section>
  </SiteLayout>
);

export default CasesGoPage;
